import React from 'react'
import styled from 'styled-components'

export const BodyWrapper = styled.div`
  position: relative;
  max-width: 405px;
  width: 100%;
  background: linear-gradient(360deg, rgba(30, 0, 56, 1) 0%, rgba(22, 0, 44, 1) 56.05%);
  border: 1px solid #a000fa;
  border-radius: 30px;
  padding: 1rem;
  z-index:10;
  `
  // background: linear-gradient(360deg, rgba(30, 0, 56, 0.45) 0%, rgba(22, 0, 44, 0.6) 56.05%);


/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children }: { children: React.ReactNode }) {
  return <BodyWrapper className='swap-body-wrapper'>{children}</BodyWrapper>
}
