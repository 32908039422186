import { ChainId } from 'tombswap-sdk'
import React, { useState, useRef } from 'react'
import { isMobile } from 'react-device-detect'
import { Text } from 'rebass'

import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { Disclosure } from '@headlessui/react'

import styled from 'styled-components'

import { useActiveWeb3React } from '../../hooks'
//import { useDarkModeManager } from '../../state/user/hooks'
import { useETHBalances } from '../../state/wallet/hooks'

import { YellowCard } from '../Card'
import Settings from '../Settings'
//import Menu from '../Menu'

import { RowBetween } from '../Row'
import Web3Status from '../Web3Status'
// import VersionSwitch from './VersionSwitch'
import New from '../../assets/svg/New.svg'
import { DropDownMenu, MenuItems } from './component/dropDownMenu'

const navigation = [
  { name: 'Home', href: 'https://tomb.com', current: false },
  {
    name: 'LIF3 Genesis',
    href: 'https://tomb.com/genesis',
    current: false,
    isNew: true,
    isExternal: true,
    children: [
      { name: 'Genesis Q4', isExternal: true, target: '_self', href: 'https://tomb.com/genesis', current: false },
      {
        name: 'Genesis Q3',
        isExternal: true,
        target: '_self',
        href: 'https://tomb.com/genesis-2022-q3',
        current: false
      }
    ]
  },
  {
    name: 'Cemetery',
    href: 'https://tomb.com/cemetery-v2',
    current: false,
    isNew: false,
    isExternal: true,
    children: [
      { name: 'Cemetery v2', isExternal: true, target: '_self', href: 'https://tomb.com/cemetery-v2', current: false },
      { name: 'Cemetery', isExternal: true, target: '_self', href: 'https://tomb.com/cemetery', current: false }
    ]
  },
  { name: 'Masonry', href: 'https://tomb.com/masonry', current: false },
  { name: 'Swap', isInternal: true, target: '_self', href: 'https://swap.tomb.com/', current: true }
]

const navMoreMenu: MenuItems[] = [
  { name: 'Pit', href: 'https://tomb.com/pit', current: false },
  { name: 'Strategies', href: 'https://tomb.com/strategies', current: false, isNew: false },
  { name: 'Swap Info', href: 'https://info.swap.tomb.com/', current: false },
  { name: 'Docs', href: 'https://docs.tomb.com/', current: false },
  { name: 'Grave', href: 'https://grave.tomb.com/', current: false }
]

const mobileMenus = [
  { name: 'Home', href: '/', current: true },
  { name: 'Cemetery v2', isExternal: true, target: '_self', href: 'https://tomb.com/cemetery-v2', current: false },
  { name: 'Genesis Q4', isExternal: false, target: '_self', href: 'https://tomb.com/genesis', current: false },
  { name: 'Swap', isExternal: true, target: '_self', href: 'https://swap.tomb.com/', current: false },
  { name: 'Pit', href: 'https://tomb.com/pit', current: false },
  { name: 'Masonry', href: 'https://tomb.com/masonry', current: false },
  {
    name: 'Genesis Q3',
    isExternal: true,
    target: '_self',
    href: 'https://tomb.com/2022-q3-genesis-pools',
    current: false
  },
  { name: 'Cemetery V1', isExternal: false, target: '_self', href: 'https://tomb.com/cemetery', current: false },
  { name: 'Swap Info', isExternal: true, target: '_self', href: 'https://info.swap.tomb.com/', current: false },
  { name: 'Strategies', href: 'https://tomb.com/strategies', current: false, isNew: false },
  { name: 'Docs', isExternal: true, target: '_self', href: 'https://docs.tomb.finance/', current: false },
  { name: 'Grave', isExternal: true, target: '_self', href: 'https://grave.tomb.com/', current: false }
]

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ')
}

const HeaderFrame = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  top: 0;
  position: absolute;
  z-index: 2;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 12px 0 0 0;
    width: calc(100%);
    position: relative;
  `};
  background: linear-gradient(180deg, #362745 16.15%, rgba(54, 39, 69, 0) 100%);
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;
`

const HeaderElementWrap = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 0.5rem;
`};
`

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  text-decoration: none;
  text-decoration-style: unset;

  :hover {
    cursor: pointer;
  }
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: ${({ active }) => (!active ? '' : '1px solid')};
  border-color: ${({ active }) => (!active ? '' : '#a000fa')};
  border-radius: 1rem;
  white-space: nowrap;
  width: 100%;
  height: 50px;

  :focus {
    border: 1px solid blue;
  }
`
// background-color: ${({ theme, active }) => (!active ? theme.bg1 : theme.bg3)};

const TestnetWrapper = styled.div`
  white-space: nowrap;
  width: fit-content;
  margin-left: 10px;
  pointer-events: auto;
`

const NetworkCard = styled(YellowCard)`
  width: fit-content;
  margin-right: 10px;
  border-radius: 12px;
  padding: 8px 12px;
`

const UniIcon = styled.div`
  transition: transform 0.3s ease;
  /* :hover {
    transform: rotate(-5deg);
  } */
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    align-items: flex-start;
  `};
`

const BalanceText = styled(Text)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`

const NETWORK_LABELS: { [chainId in ChainId]: string | null } = {
  [ChainId.MAINNET]: 'Fantom',
  [ChainId.FTMTESTNET]: 'Testnet',
  [ChainId.ETHMAINNET]: 'Ethereum',
  [ChainId.BSC]: 'BSC'
}

const MoreMenuBg = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  position: relative;
  max-width: 12rem;
  width: 100%;
  background: linear-gradient(360deg, rgba(30, 0, 56, 1) 0%, rgba(22, 0, 44, 1) 56.05%);
  border: 1px solid #a000fa;
  border-radius: 20px;
  padding: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`

export function MoreMenu() {
  return (
    <MoreMenuBg>
      <div className="py-2 px-3">
        {navMoreMenu.map(item => {
          return (
            <div
              key={item.name}
              className={`relative
                    ${item.current ? 'nav-selected textShadowLgSelected my-2' : 'hoverEffect my-2'}   
                  `}
            >
              {item.isNew && (
                <NewIcon>
                  <img src={New} alt="" />
                </NewIcon>
              )}
              <a href={item.href} aria-current={item.current ? 'page' : undefined}>
                <span className="menu-item-style">{item.name}</span>
              </a>
            </div>
          )
        })}
      </div>
    </MoreMenuBg>
  )
}

const NewIcon = styled.div`
  position: absolute;
  top: -15px;
  right: -10px;
  z-index: 999;
  width: 33px;
`

export default function Header() {
  const { account, chainId } = useActiveWeb3React()

  const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']
  //const [isDark] = useDarkModeManager()

  const [toggleMoreMenu, setToggleMoreMenu] = useState('')

  const ddRef = useRef<any>(null)
  const handleMouseLeave = (event: any) => {
    if (ddRef?.current && !ddRef.current?.contains(event.target)) {
      setToggleMoreMenu('')
    } else {
      setToggleMoreMenu('')
    }
  }

  return (
    <Disclosure as="nav" style={{ width: '100%' }}>
      {({ open }) => (
        <HeaderFrame className="header-frame">
          <RowBetween style={{ alignItems: 'flex-start' }} padding="1rem 1rem 0 1rem">
            <div className="inset-y-0 left-0 flex items-center md:hidden">
              {/* Mobile menu button*/}
              <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                <span className="sr-only">Open main menu</span>
                {open ? (
                  <XIcon className="block h-6 w-6" aria-hidden="true" />
                ) : (
                  <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                )}
              </Disclosure.Button>
            </div>
            <HeaderElement className="mx-auto md:mx-0">
              <Title href=".">
                <UniIcon>
                  {/* className="font-Amarante text-2xl md:text-3xl cursor-pointer select-none textShadowLg"> */}
                  <span className="logo-text textShadowLg">Tomb</span>
                </UniIcon>
              </Title>
            </HeaderElement>
            <HeaderElement>
              <div className="menu-container3">
                {navigation.map(item => {
                  return (
                    <div
                      key={item.name}
                      className={`relative 
                    ${item.current ? 'nav-selected textShadowLgSelected' : 'hoverEffect'}
                  `}
                    >
                      {item.isNew && (
                        <NewIcon>
                          <img src={New} alt="" />
                        </NewIcon>
                      )}

                      {item?.children && item?.children.length > 0 ? (
                        <DropDownMenu
                          setToggleMoreMenu={setToggleMoreMenu}
                          toggleMoreMenu={toggleMoreMenu}
                          handleMouseLeave={handleMouseLeave}
                          dropDownMenuItems={item.children}
                          parentItem={item}
                        />
                      ) : (
                        <a href={item.href} aria-current={item.current ? 'page' : undefined}>
                          <span className="menu-item-style">{item.name}</span>
                        </a>
                      )}
                    </div>
                  )
                })}
                <DropDownMenu
                  setToggleMoreMenu={setToggleMoreMenu}
                  toggleMoreMenu={toggleMoreMenu}
                  handleMouseLeave={handleMouseLeave}
                  dropDownMenuItems={navMoreMenu}
                  parentItem={{ name: 'More' }}
                />
              </div>
            </HeaderElement>
            <HeaderControls className="header-controls wallet-section">
              <HeaderElement>
                <TestnetWrapper style={{ display: 'none' }}>
                  {!isMobile && chainId && NETWORK_LABELS[chainId] && (
                    <NetworkCard>{NETWORK_LABELS[chainId]}</NetworkCard>
                  )}
                </TestnetWrapper>
                <AccountElement className="wallet-account-element" active={!!account} style={{ pointerEvents: 'auto' }}>
                  {account && userEthBalance ? (
                    <BalanceText style={{ flexShrink: 0 }} pl="0.75rem" pr="0.5rem" fontWeight={500}>
                      {userEthBalance?.toSignificant(4)} FTM
                    </BalanceText>
                  ) : null}
                  <Web3Status />
                </AccountElement>
              </HeaderElement>
              {!!account && (
                <HeaderElementWrap>
                  {/* <VersionSwitch /> */}
                  <Settings />
                  {/* <Menu /> */}
                </HeaderElementWrap>
              )}
            </HeaderControls>
          </RowBetween>
          <Disclosure.Panel className="md:hidden mobile-menu" style={{ width: '100%' }}>
            <div className="px-2 pt-2 pb-3 space-y-1">
              {mobileMenus.map(item => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.name === 'Swap' || item.href === window.location.pathname
                      ? 'bg-gray-900'
                      : 'hover:bg-gray-700 hover:text-white',
                    'block px-3 py-2 rounded-md text-base font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </HeaderFrame>
      )}
    </Disclosure>
  )
}
