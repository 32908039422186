import { Currency, CurrencyAmount, ETHER, JSBI, Token, TokenAmount, WETH } from 'tombswap-sdk'
import { useMemo } from 'react'
import { uniq } from 'lodash'
import { BigNumber } from 'ethers'
import { useQuery } from 'react-query'

import ERC20_INTERFACE, { ERC20_ABI } from '../../constants/abis/erc20'
import { useAllTokens, useDefaultTokenList } from '../../hooks/Tokens'
import { useActiveWeb3React } from '../../hooks'
import { useMulticallContract } from '../../hooks/useContract'
import { getContract, isAddress } from '../../utils'
import { useSingleContractMultipleData, useMultipleContractSingleData } from '../multicall/hooks'
import { useUserAddedTokensMap } from 'state/user/hooks'
import { multicall } from 'utils/multicall'
import IUniswapV2PairABI from 'tombswap-sdk/abis/IUniswapV2Pair.json'

/**
 * Returns a map of the given addresses to their eventually consistent ETHER balances.
 */
export function useETHBalances(
  uncheckedAddresses?: (string | undefined)[]
): { [address: string]: CurrencyAmount | undefined } {
  const multicallContract = useMulticallContract()

  const addresses: string[] = useMemo(
    () =>
      uncheckedAddresses
        ? uncheckedAddresses
            .map(isAddress)
            .filter((a): a is string => a !== false)
            .sort()
        : [],
    [uncheckedAddresses]
  )

  const results = useSingleContractMultipleData(
    multicallContract,
    'getEthBalance',
    addresses.map(address => [address])
  )

  return useMemo(
    () =>
      addresses.reduce<{ [address: string]: CurrencyAmount }>((memo, address, i) => {
        const value = results?.[i]?.result?.[0]
        if (value) memo[address] = CurrencyAmount.ether(JSBI.BigInt(value.toString()))
        return memo
      }, {}),
    [addresses, results]
  )
}

/**
 * Returns a map of token addresses to their eventually consistent token balances for a single account.
 */
export function useTokenBalancesWithLoadingIndicator(
  address?: string,
  tokens?: (Token | undefined)[]
): [{ [tokenAddress: string]: TokenAmount | undefined }, boolean] {
  const validatedTokens: Token[] = useMemo(
    () => tokens?.filter((t?: Token): t is Token => isAddress(t?.address) !== false) ?? [],
    [tokens]
  )

  const validatedTokenAddresses = useMemo(() => validatedTokens.map(vt => vt.address), [validatedTokens])

  const balances = useMultipleContractSingleData(validatedTokenAddresses, ERC20_INTERFACE, 'balanceOf', [address])

  const anyLoading: boolean = useMemo(() => balances.some(callState => callState.loading), [balances])

  return [
    useMemo(
      () =>
        address && validatedTokens.length > 0
          ? validatedTokens.reduce<{ [tokenAddress: string]: TokenAmount | undefined }>((memo, token, i) => {
              const value = balances?.[i]?.result?.[0]
              const amount = value ? JSBI.BigInt(value.toString()) : undefined
              if (amount) {
                memo[token.address] = new TokenAmount(token, amount)
              }
              return memo
            }, {})
          : {},
      [address, validatedTokens, balances]
    ),
    anyLoading
  ]
}

export function useTokenBalances(
  address?: string,
  tokens?: (Token | undefined)[]
): { [tokenAddress: string]: TokenAmount | undefined } {
  return useTokenBalancesWithLoadingIndicator(address, tokens)[0]
}

// get the balance for a single token/account combo
export function useTokenBalance(account?: string, token?: Token): TokenAmount | undefined {
  const tokenBalances = useTokenBalances(account, [token])
  if (!token) return undefined
  return tokenBalances[token.address]
}

export function useCurrencyBalances(
  account?: string,
  currencies?: (Currency | undefined)[]
): (CurrencyAmount | undefined)[] {
  const tokens = useMemo(() => currencies?.filter((currency): currency is Token => currency instanceof Token) ?? [], [
    currencies
  ])

  const tokenBalances = useTokenBalances(account, tokens)
  const containsETH: boolean = useMemo(() => currencies?.some(currency => currency === ETHER) ?? false, [currencies])
  const ethBalance = useETHBalances(containsETH ? [account] : [])

  return useMemo(
    () =>
      currencies?.map(currency => {
        if (!account || !currency) return undefined
        if (currency instanceof Token) {
          return tokenBalances[currency.address]
        }
        if (currency === ETHER) return ethBalance[account]
        return undefined
      }) ?? [],
    [account, currencies, ethBalance, tokenBalances]
  )
}

export function useCurrencyBalance(account?: string, currency?: Currency): CurrencyAmount | undefined {
  return useCurrencyBalances(account, [currency])[0]
}

// mimics useAllBalances
export function useAllTokenBalances(): { [tokenAddress: string]: TokenAmount | undefined } {
  const { account } = useActiveWeb3React()
  const allTokens = useAllTokens()
  const allTokensArray = useMemo(() => Object.values(allTokens ?? {}), [allTokens])
  const balances = useTokenBalances(account ?? undefined, allTokensArray)
  return balances ?? {}
}

export const useCurrencyBalanceQuery = (currency: Currency | undefined | null) => {
  const { account, library } = useActiveWeb3React()

  const tokenAddress = currency instanceof Token ? currency?.address : ETHER.symbol ?? 'FTM'

  return useQuery<TokenAmount | null | undefined>(
    ['token-balance', 'single', tokenAddress, account],
    async () => {
      if (tokenAddress === ETHER.symbol) {
        const ethBalanceResponse = account ? await library?.getBalance(account, 'pending') : BigNumber.from(0)

        const ethBalance = new TokenAmount(WETH[250], JSBI.BigInt(ethBalanceResponse?.toString() ?? '0'))

        return ethBalance
      }

      const tokenContract = getContract(tokenAddress, ERC20_ABI, library as any, account as string)

      const balanceResponse: BigNumber = await tokenContract.balanceOf(account, { blockTag: 'pending' })
      const amount = balanceResponse ? JSBI.BigInt(balanceResponse.toString()) : undefined

      if (amount && currency) {
        return new TokenAmount(currency as Token, amount.toString())
      }

      return undefined
    },
    {
      enabled: Boolean(account) && Boolean(currency)
    }
  )
}

export const useCurrenciesBalanceQuery = () => {
  const { account, library } = useActiveWeb3React()
  const userAddedTokensMap = useUserAddedTokensMap()
  const listedTokens = useDefaultTokenList()

  const tokens = { [WETH[250].address]: WETH[250], ...userAddedTokensMap, ...listedTokens }
  const tokenAddresses: string[] = uniq(Object.values(tokens).map(token => token.address)).sort((addressA, addressB) =>
    addressA.toLowerCase() > addressB.toLowerCase() ? 1 : -1
  )

  const balancesQuery = useQuery<Record<string, TokenAmount>>(
    ['token-balances', 'multicall', account],
    async () => {
      const calls = tokenAddresses.map(address => ({
        address,
        name: 'balanceOf',
        params: [account]
      }))

      const balances = await multicall(IUniswapV2PairABI.abi, calls, library as any)

      const ethBalanceResponse = account ? await library?.getBalance(account) : BigNumber.from(0)

      const ethBalance = new TokenAmount(WETH[250], JSBI.BigInt(ethBalanceResponse?.toString() ?? '0'))

      const finalBalances = balances.reduce((acc: Record<string, TokenAmount>, balance: any, index: number) => {
        const amount = JSBI.BigInt(balance[0].toString())

        const tokenAddress = tokenAddresses[index]
        const token = tokens[tokenAddress]

        if (amount && token) {
          acc[token.address] = new TokenAmount(token as Token, amount.toString())
        }

        return acc
      }, {})

      return {
        ...finalBalances,
        [ETHER.symbol ?? 'ETH']: ethBalance
      }
    },
    {
      enabled: Boolean(account)
    }
  )

  return balancesQuery
}
