import React from 'react';
import twitter from '../../assets/img/twitter.svg';
import github from '../../assets/img/github.svg';
import discord from '../../assets/img/discord.svg';
import telegram from '../../assets/img/telegram.svg';
import youtube from '../../assets/img/youtube.svg';

function Footer() {
  return (
    <div className="footer-mainContainer fixed">
            <span className="footer-tombfinance">Tomb</span>
            <span className="footer-copyright">Copyright © Tomb 2022</span>
            <div className="footer-linkContainer">
              <a href="https://twitter.com/tombfinance" target="_blank" rel="noreferrer noopener">
                <img src={twitter} width={20} height={20} alt="tombswap twitter"/>
              </a>
              <a href="https://github.com/tombfinance " target="_blank" rel="noreferrer noopener">
                <img src={github} width={20} height={20} alt="tombswap github" />
              </a>
              <a style={{marginTop: 4}} href="https://discord.gg/vANnESmVdz" target="_blank" rel="noreferrer noopener">
                <img src={discord} width={19} height={19} alt="tombswap discord" />
              </a>
              {/* <a className="mt-1" href="https://discord.gg/vANnESmVdz" target="_blank" rel="noreferrer noopener">
                <img src={boo} width={19} height={19} />
              </a> */}
              <a style={{marginTop: 4}} href="https://t.me/gameoftomb" target="_blank" rel="noreferrer noopener">
                <img src={telegram} width={19} height={19} alt="tombswap telegram" />
              </a>
              <a
                style={{marginTop: 4}}
                href="https://www.youtube.com/channel/UCYKO4BkSr4tBYjOo1ilK1SA"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img src={youtube} width={19} height={19} alt="tombswap youtube" />
              </a>
            </div>
          </div>
  );
}

export default Footer;
