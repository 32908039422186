import { TokenAmount, Pair, Currency, ChainId, FACTORY_ADDRESS, Token } from 'tombswap-sdk'
import { useMemo } from 'react'
import { abi as IUniswapV2PairABI } from '@uniswap/v2-core/build/IUniswapV2Pair.json'

import { wrappedCurrency } from '../utils/wrappedCurrency'
import { getContract } from 'utils'
import factoryAbi from '../constants/abis/factory.json'
import { ReadOnlyProvider } from 'connectors'
import { useQuery } from 'react-query'
import { ZERO_ADDRESS } from '../constants'
import { BigNumber } from 'ethers'
import { usePairsReservesQuery } from 'hooks/queries/usePairsReservesQuery'
import { useAllPairCombinations } from 'hooks/Trades'

export enum PairState {
  LOADING,
  NOT_EXISTS,
  EXISTS,
  INVALID
}

export const useReservesTokensMap = (currencies: [Currency, Currency][]) => {
  const tokens = useMemo(
    () =>
      currencies
        .map(([currencyA, currencyB]) => [
          wrappedCurrency(currencyA, ChainId.MAINNET),
          wrappedCurrency(currencyB, ChainId.MAINNET)
        ])
        .reduce<Record<string, { tokenA: Token; tokenB: Token }>>((acc, [tokenA, tokenB]) => {
          const address = tokenA && tokenB && !tokenA.equals(tokenB) ? Pair.getAddress(tokenA, tokenB) : undefined

          if (address && tokenA && tokenB) {
            acc[address] = { tokenA, tokenB }
          }

          return acc
        }, {}),
    [currencies]
  )

  return tokens
}

export function usePairs(currencies: [Currency, Currency][]): [PairState, Pair | null][] {
  const tokens = useReservesTokensMap(currencies)

  const pairsReservesQuery = usePairsReservesQuery(tokens)

  const results = pairsReservesQuery.data ?? []

  if (pairsReservesQuery.isIdle) return [[PairState.NOT_EXISTS, null]]

  if (pairsReservesQuery.isLoading) return [[PairState.LOADING, null]]

  if (pairsReservesQuery.isError) {
    return [[PairState.INVALID, null]]
  }

  return results.map((result: any) => {
    const resultPair = tokens[result.pairAddress]
    const tokenA = resultPair.tokenA
    const tokenB = resultPair.tokenB

    const { reserve0, reserve1 } = result

    if (pairsReservesQuery.isLoading) return [PairState.LOADING, null]
    if (!tokenA || !tokenB || tokenA.equals(tokenB)) return [PairState.INVALID, null]
    if (!reserve0 || !reserve1) return [PairState.NOT_EXISTS, null]

    const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA]

    return [
      PairState.EXISTS,
      new Pair(new TokenAmount(token0, reserve0.toString()), new TokenAmount(token1, reserve1.toString()))
    ]
  })
}

export function usePair(
  tokenA: Currency | undefined | null,
  tokenB: Currency | undefined | null
): [PairState, Pair | null] {
  const wrappedTokenA = wrappedCurrency(tokenA, ChainId.MAINNET)
  const wrappedTokenB = wrappedCurrency(tokenB, ChainId.MAINNET)

  const pairAddress =
    wrappedTokenA && wrappedTokenB && !wrappedTokenA.equals(wrappedTokenB)
      ? Pair.getAddress(wrappedTokenA, wrappedTokenB)
      : undefined

  const factoryContract = getContract(FACTORY_ADDRESS, factoryAbi, ReadOnlyProvider)

  const pairReservesQuery = useQuery(
    ['pair-reserves', pairAddress],
    async () => {
      const pairContract = getContract(pairAddress ?? '', IUniswapV2PairABI, ReadOnlyProvider)

      const res = await factoryContract?.getPair(wrappedTokenA?.address, wrappedTokenB?.address)

      if (res === ZERO_ADDRESS) {
        return { reserve0: undefined, reserve1: undefined }
      }

      const reserve: { reserve1: BigNumber; reserve0: BigNumber } = await pairContract.getReserves()

      return { ...reserve }
    },
    { enabled: Boolean(pairAddress) }
  )

  if (pairReservesQuery.isLoading) return [PairState.LOADING, null]
  if (!wrappedTokenA || !wrappedTokenB || wrappedTokenA.equals(wrappedTokenB)) return [PairState.INVALID, null]
  if (!pairReservesQuery.data?.reserve0 || !pairReservesQuery.data?.reserve1) return [PairState.NOT_EXISTS, null]

  const [token0, token1] = wrappedTokenA.sortsBefore(wrappedTokenB)
    ? [wrappedTokenA, wrappedTokenB]
    : [wrappedTokenB, wrappedTokenA]

  return [
    PairState.EXISTS,
    new Pair(
      new TokenAmount(token0, pairReservesQuery.data.reserve0.toString()),
      new TokenAmount(token1, pairReservesQuery.data.reserve1.toString())
    )
  ]
}

export const usePreloadReserves = (
  currencyA?: Currency | null | undefined,
  currencyB?: Currency | null | undefined
) => {
  const allPairCombinations = useAllPairCombinations(currencyA, currencyB)
  const tokens = useReservesTokensMap(allPairCombinations)

  usePairsReservesQuery(tokens)
}
