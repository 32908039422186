import React, { useCallback, useEffect, useState /* , useCallback */ } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { ButtonEmpty, ButtonLight } from '../Button'
import { OrderList } from './orderLists'
import { useActiveWeb3React } from '../../hooks'
// import { SwapCallbackError } from './styleds'
import { GelatoLimitOrders, ChainId, Order } from '@gelatonetwork/limit-orders-lib'

const Tabs = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  border-radius: 16px;
  justify-content: space-evenly;
  background: linear-gradient(360deg, rgba(30, 0, 56, 0.45) 0%, rgba(22, 0, 44, 0.6) 56.05%);
  border: 1px solid #a000fa;
  max-width: 10rem;
`

const activeClassName = 'ACTIVE'

const NavigationStyleButton = styled(ButtonEmpty).attrs({
  activeClassName
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text3};
  font-size: 1rem;
  width: 50%;

  &.${activeClassName} {
    border-radius: 16px;
    font-weight: 500;
    color: ${({ theme }) => theme.tombText};
  }

  :hover,
  :focus {
    color: ${({ theme }) => theme.tombText};
  }
`

export function OpenPastTab({
  active,
  handleOrderLists,
  resetPage
}: {
  active: 'open' | 'past'
  handleOrderLists: any
  resetPage: any
}) {
  const { t } = useTranslation()
  return (
    <Tabs style={{ width: '100%' }}>
      <NavigationStyleButton
        className={`h-8 text-sm ${active === 'open' ? 'ACTIVE btn' : ''}`}
        data-type="open"
        onClick={e => {
          handleOrderLists(e)
          resetPage()
        }}
      >
        {t('OPEN')}
      </NavigationStyleButton>
      <NavigationStyleButton
        className={` cursor-not-allowed h-8 text-sm ${active === 'past' ? 'ACTIVE btn' : ''}`}
        data-type="past"
        onClick={e => {
          handleOrderLists(e)
          resetPage()
        }}
      >
        {t('PAST')}
      </NavigationStyleButton>
    </Tabs>
  )
}

export function PaginationTab({
  page,
  total,
  limitPerPage,
  handlePageClick
}: {
  page: number
  total: number
  limitPerPage: number
  handlePageClick: any
}) {
  const { t } = useTranslation()
  const hasNext = total > (page + 1) * limitPerPage
  const hasPrev = page > 0
  return (
    <div className="mt-4 flex justify-center gap-x-6" style={{ width: '100%' }}>
      <ButtonLight
        style={{ cursor: `${!hasPrev ? 'not-allowed' : ''}` }}
        disabled={!hasPrev}
        className={`h-8 text-sm btn relative`}
        data-type="prev"
        onClick={e => handlePageClick(e)}
      >
        {t('Prev')}
      </ButtonLight>
      <ButtonLight
        style={{ cursor: `${!hasNext ? 'not-allowed' : ''}` }}
        disabled={!hasNext}
        className={`h-8 text-sm btn relative`}
        data-type="next"
        onClick={e => handlePageClick(e)}
      >
        {t('Next')}
      </ButtonLight>
    </div>
  )
}

export default function OrdersPanel(props: any) {
  const { handleOrderLists, currentOrderListType, transaction, setSwapState, limitPerPage } = props

  const [isOrderLoading, setIsOrderLoading] = useState<boolean>(false)
  const [pastOrderLists, setPastOrderLists] = useState<any[]>([])
  const [openOrderLists, setOpenOrderLists] = useState<any[]>([])

  const [currentOrderLists, setCurrentOrderLists] = useState<any[]>([])
  const [offset, setOffset] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const [total, setTotal] = useState<number>(0)

  const { account, library, chainId } = useActiveWeb3React()
  const handler = 'tombswap'

  const handlePageClick = (e: any) => {
    let { type } = e?.target.dataset
    let currPage = page
    if (type === 'prev') {
      currPage = page - 1
    } else {
      currPage = page + 1
    }
    setPage(currPage)
    setOffset(currPage * limitPerPage)
  }

  const resetPage = () => {
    setPage(0)
    setOffset(0)
  }

  const fetchOrders = useCallback(async () => {
    console.log('fetching order')
    setIsOrderLoading(true)
    const signer = library?.getSigner(account ?? undefined).connectUnchecked()
    if (account) {
      const gelatoLimitOrders = new GelatoLimitOrders(chainId as ChainId, signer, handler)
      let openOrder = await gelatoLimitOrders.getOpenOrders(account)
      let pastOrder = await gelatoLimitOrders.getPastOrders(account)
      /* console.log('openOrder', openOrder, pastOrder)
      let tmp = openOrder
      for (let i = 0; i <= 20; i++) {
        openOrder.push(tmp[Math.floor(Math.random() * 2)])
        pastOrder.push(tmp[Math.floor(Math.random() * 2)])
      } */

      setIsOrderLoading(false)
      setOpenOrderLists(openOrder.reverse())
      setPastOrderLists(pastOrder.reverse())
    } else {
      setIsOrderLoading(false)
    }
  }, [account, chainId, library])

  useEffect(() => {
    fetchOrders()
  }, [transaction, fetchOrders])

  useEffect(() => {
    if (currentOrderListType === 'open') {
      let tempOrder = openOrderLists
      setTotal(openOrderLists.length)
      setCurrentOrderLists(tempOrder.slice(offset, offset + limitPerPage))
    } else {
      console.log(pastOrderLists.length, total)
      let tempOrder = pastOrderLists
      setTotal(pastOrderLists.length)
      setCurrentOrderLists(tempOrder.slice(offset, offset + limitPerPage))
    }
  }, [page, offset, openOrderLists, pastOrderLists, currentOrderListType, limitPerPage])

  const cancelOrder = async (order: Order) => {
    const signer = library?.getSigner(account ?? undefined).connectUnchecked()
    console.log('signer', signer)
    const gelatoLimitOrders = new GelatoLimitOrders(chainId as ChainId, signer, handler)
    return await gelatoLimitOrders
      .cancelLimitOrder(order)
      .then(async res => {
        let openOrder = await gelatoLimitOrders.getOpenOrders(account ?? '')
        let pastOrder = await gelatoLimitOrders.getPastOrders(account ?? '')
        // let cancelledOrder = await gelatoLimitOrders.getCancelledOrders(account ?? '')
        // let executedOrder = await gelatoLimitOrders.getExecutedOrders(account??'')
        setIsOrderLoading(false)
        setOpenOrderLists(openOrder.reverse())
        setPastOrderLists(pastOrder.reverse())
      })
      .catch(error => {
        setSwapState({
          attemptingTxn: false,
          tradeToConfirm: undefined,
          showConfirm: true,
          swapErrorMessage: error.message,
          txHash: undefined
        })
      })
  }

  return (
    <>
      <div className="flex justify-between">
        <h1 className="text-base font-medium tomb-text">Orders</h1>
        <OpenPastTab active={currentOrderListType} handleOrderLists={handleOrderLists} resetPage={resetPage} />
      </div>
      <div style={{ width: '100%', borderBottom: '1px solid rgba(148, 1, 251, 1)' }} className="mx-auto mt-4"></div>
      {currentOrderListType === 'open' ? (
        currentOrderLists.length ? (
          <Items orders={currentOrderLists} type={'open'} cancelOrder={cancelOrder} />
        ) : (
          <div className="flex justify-between cursor-pointer p-5">{`${
            isOrderLoading ? 'loading...' : 'No orders'
          }`}</div>
        )
      ) : currentOrderLists.length ? (
        <Items orders={currentOrderLists} type={'past'} cancelOrder={cancelOrder} />
      ) : (
        <div className="flex justify-between cursor-pointer p-5">{`${
          isOrderLoading ? 'loading...' : 'No orders'
        }`}</div>
      )}
      <div style={{ width: '100%', borderBottom: '1px solid rgba(148, 1, 251, 1)' }} className="mx-auto mt-4"></div>
      <PaginationTab total={total} page={page} limitPerPage={limitPerPage} handlePageClick={handlePageClick} />
    </>
  )
}

export function Items({ orders, type, cancelOrder }: { orders: any; type: 'open' | 'past'; cancelOrder: any }) {
  return (
    <>
      {orders.map((order: any, index: any) => {
        return <OrderList key={index} type={type} order={order} cancelOrder={cancelOrder} />
      })}
    </>
  )
}