import { useQuery } from 'react-query'
import { request, gql } from 'graphql-request'
import Big from 'big.js'

import { SWAP_SUBGRAPH_URL } from '../../constants'

export const gqlQuery = gql`
  query tokenInfo($tokenAddress: Bytes!) {
    token(id: $tokenAddress) {
      derivedETH
    }
    bundle(id: 1) {
      ethPrice
    }
  }
`

type Response = {
  token: {
    derivedETH: string
  }
  bundle: {
    ethPrice: string
  }
}

export const useTokenInfoQuery = (tokenAddress: string | undefined) => {
  return useQuery(
    ['get-token-info', tokenAddress],
    async () => {
      const response = await request<Response>(SWAP_SUBGRAPH_URL, gqlQuery, {
        tokenAddress: tokenAddress?.toLowerCase()
      })

      const USDPrice = Big(response.token.derivedETH).mul(response.bundle.ethPrice)

      return {
        USDPrice: Number(USDPrice)
      }
    },
    { enabled: Boolean(tokenAddress) }
  )
}
