import { Trade, TradeType } from 'tombswap-sdk'
import React, { useContext, useEffect, useState } from 'react'
import { ThemeContext } from 'styled-components'
import { Field } from '../../state/swap/actions'
import { useUserSlippageTolerance } from '../../state/user/hooks'
import { TYPE } from '../../theme'
import { computeSlippageAdjustedAmounts, computeTradePriceBreakdown } from '../../utils/prices'
import { AutoColumn } from '../Column'
import QuestionHelper from '../QuestionHelper'
import { RowBetween, RowFixed } from '../Row'
import FormattedPriceImpact from './FormattedPriceImpact'
import { SectionBreak } from './styleds'
import SwapRoute from './SwapRoute'

import { useSwapState } from '../../state/swap/hooks'
import { tryParseAmount } from '../../state/swap/hooks'
import { useDerivedSwapInfo } from '../../state/swap/hooks'
import { useActiveWeb3React } from '../../hooks'
import { GelatoLimitOrders, ChainId } from '@gelatonetwork/limit-orders-lib'
import { ethers } from 'ethers'

function TradeSummary({ trade, allowedSlippage, ...rest }: { trade: Trade; allowedSlippage: number, isLimit?: boolean }) {
  const theme = useContext(ThemeContext)
  const { priceImpactWithoutFee, realizedLPFee } = computeTradePriceBreakdown(trade)
  const isExactIn = trade.tradeType === TradeType.EXACT_INPUT
  const slippageAdjustedAmounts = computeSlippageAdjustedAmounts(trade, allowedSlippage)

  const { currencies } = useDerivedSwapInfo()

  const { limitValue } = useSwapState()
  const { isLimit } = rest;
  const inputAmount = trade?.inputAmount.toSignificant(6)
  const limitOutput = parseFloat(limitValue ?? '0') * parseFloat(inputAmount);

  const outputAmont = ethers.utils.parseUnits(limitOutput ? `${limitOutput}` : '0', currencies[Field.OUTPUT]?.decimals)

  const [limitOrderMinReturn, setLimitOrderMinReturn] = useState<string>('');
  const { account, library, chainId } = useActiveWeb3React();
  const signer = library?.getSigner(account ?? '').connectUnchecked()
  const handler = 'tombswap'
  const gelatoLimitOrders = new GelatoLimitOrders(chainId as ChainId, signer, handler)

  const calculateTrade = async (gelatoLimitOrders: GelatoLimitOrders) => {
    const slippageAdjustedAmount = await gelatoLimitOrders
      .getFeeAndSlippageAdjustedMinReturn(outputAmont, allowedSlippage)
    const { minReturn } = slippageAdjustedAmount //slippage and gelato fee can alsp be obtained from here
    setLimitOrderMinReturn(minReturn);
  }

  const getAdjustedPrice = () => {
    const adjustedPrice = tryParseAmount(
      ethers.utils.formatUnits(limitOrderMinReturn, currencies[Field.OUTPUT]?.decimals),
      currencies[Field.OUTPUT]
    )
    return `${adjustedPrice?.toSignificant(4)} ${trade.outputAmount.currency.symbol}`
  }

  useEffect(() => {
    calculateTrade(gelatoLimitOrders)
  }, [limitValue])

  return (
    <>
      <AutoColumn style={{ padding: '0 20px' }}>
        <RowBetween>
          <RowFixed>
            <TYPE.black fontSize={14} fontWeight={400} color={theme.text2}>
              {isExactIn ? 'Minimum received' : 'Maximum sold'}
            </TYPE.black>
            <QuestionHelper text="Your transaction will revert if there is a large, unfavorable price movement before it is confirmed." />
          </RowFixed>
          <RowFixed>
          {(limitValue && isLimit) ? (
              <TYPE.black color={theme.text1} fontSize={14}>
                {getAdjustedPrice()}
              </TYPE.black>
            ) : (
              <TYPE.black color={theme.text1} fontSize={14}>
                {isExactIn
                  ? `${slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4)} ${trade.outputAmount.currency.symbol
                  }` ?? '-'
                  : `${slippageAdjustedAmounts[Field.INPUT]?.toSignificant(4)} ${trade.inputAmount.currency.symbol}` ??
                  '-'}
              </TYPE.black>
            )}
          </RowFixed>
        </RowBetween>
        <RowBetween>
          <RowFixed>
            <TYPE.black fontSize={14} fontWeight={400} color={theme.text2}>
              Price Impact
            </TYPE.black>
            <QuestionHelper text="The difference between the market price and estimated price due to trade size." />
          </RowFixed>
          <FormattedPriceImpact priceImpact={priceImpactWithoutFee} />
        </RowBetween>

        <RowBetween>
          <RowFixed>
            <TYPE.black fontSize={14} fontWeight={400} color={theme.text2}>
              Liquidity Provider Fee
            </TYPE.black>
            <QuestionHelper text="A portion of each trade (0.25%) goes to liquidity providers and 0.25% goes to the protocol." />
          </RowFixed>
          <TYPE.black fontSize={14} color={theme.text1}>
            {realizedLPFee ? `${realizedLPFee.toSignificant(6)} ${trade.inputAmount.currency.symbol}` : '-'}
          </TYPE.black>
        </RowBetween>
      </AutoColumn>
    </>
  )
}

export interface AdvancedSwapDetailsProps {
  trade?: Trade,
  isLimit?: boolean
}

export function AdvancedSwapDetails({ trade, ...rest }: AdvancedSwapDetailsProps) {
  const theme = useContext(ThemeContext)

  const [allowedSlippage] = useUserSlippageTolerance()

  const showRoute = Boolean(trade && trade.route.path.length > 2)

  return (
    <AutoColumn gap="md">
      {trade && (
        <>
          <TradeSummary trade={trade} allowedSlippage={allowedSlippage} {...rest} />
          {showRoute && (
            <>
              <SectionBreak />
              <AutoColumn style={{ padding: '0 24px' }}>
                <RowFixed>
                  <TYPE.black fontSize={14} fontWeight={400} color={theme.text2}>
                    Route
                  </TYPE.black>
                  <QuestionHelper text="Routing through these tokens resulted in the best price for your trade." />
                </RowFixed>
                <SwapRoute trade={trade} />
              </AutoColumn>
            </>
          )}
        </>
      )}
    </AutoColumn>
  )
}
