import { CurrencyAmount, JSBI, Token, Trade, ETHER, Currency } from 'tombswap-sdk'

import { MaxUint256 } from '@ethersproject/constants'

import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ArrowDown } from 'react-feather'
import ReactGA from 'react-ga'
import { Text } from 'rebass'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from 'styled-components'
import AddressInputPanel from '../../components/AddressInputPanel'
import { ButtonError, ButtonLight, ButtonPrimary, ButtonConfirmed } from '../../components/Button'
import Card, { GreyCard } from '../../components/Card'
import { AutoColumn } from '../../components/Column'
import ConfirmSwapModal from '../../components/swap/ConfirmSwapModal'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import { SwapPoolTabs } from '../../components/NavigationTabs'
import { MarketLimitsTabs } from '../../components/NavigationTabs'
import { AutoRow, RowBetween } from '../../components/Row'
import AdvancedSwapDetailsDropdown from '../../components/swap/AdvancedSwapDetailsDropdown'
import confirmPriceImpactWithoutFee from '../../components/swap/confirmPriceImpactWithoutFee'
import { ArrowWrapper, BottomGrouping, SwapCallbackError, Wrapper } from '../../components/swap/styleds'
import TradePrice from '../../components/swap/TradePrice'
import TokenWarningModal from '../../components/TokenWarningModal'
import ProgressSteps from '../../components/ProgressSteps'

import { INITIAL_ALLOWED_SLIPPAGE } from '../../constants'
import { useActiveWeb3React } from '../../hooks'
import { useAllTokens, useCurrency } from '../../hooks/Tokens'
import { ApprovalState, useApproveCallbackFromTrade } from '../../hooks/useApproveCallback'
import useENSAddress from '../../hooks/useENSAddress'
import { useSwapCallback } from '../../hooks/useSwapCallback'
import useToggledVersion, { Version } from '../../hooks/useToggledVersion'
import useWrapCallback, { WrapType } from '../../hooks/useWrapCallback'
import { useToggleSettingsMenu, useWalletModalToggle } from '../../state/application/hooks'
import { Field } from '../../state/swap/actions'
import {
  useDefaultsFromURLSearch,
  useDerivedSwapInfo,
  useSwapActionHandlers,
  useSwapState
} from '../../state/swap/hooks'
import { useExpertModeManager, useUserDeadline, useUserSlippageTolerance } from '../../state/user/hooks'
import { LinkStyledButton, TYPE } from '../../theme'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { computeTradePriceBreakdown, warningSeverity } from '../../utils/prices'
import AppBody from '../AppBody'
import { ClickableText } from '../Pool/styleds'
import Loader from '../../components/Loader'

import { GelatoLimitOrders, ChainId } from '@gelatonetwork/limit-orders-lib'
import { ethers } from 'ethers'
import LimitPriceDetails from '../../components/swap/LimitPriceDetails'
import OrdersPanel from '../../components/swap/OrdersPanel'
import useParsedQueryString from '../../hooks/useParsedQueryString'
import { useCurrencyBalanceQuery } from 'state/wallet/hooks'
import { usePreloadReserves } from 'data/Reserves'
import { ReadOnlyProvider } from 'connectors'

export default function Swap() {
  const { t } = useTranslation()
  const allTokens = useAllTokens()

  const loadedUrlParams = useDefaultsFromURLSearch()
  // token warning stuff
  const [loadedInputCurrency, loadedOutputCurrency] = [
    useCurrency(loadedUrlParams?.inputCurrencyId),
    useCurrency(loadedUrlParams?.outputCurrencyId)
  ]
  const [dismissTokenWarning, setDismissTokenWarning] = useState<boolean>(false)

  const urlLoadedTokens: Token[] = useMemo(
    () => [loadedInputCurrency, loadedOutputCurrency]?.filter((c): c is Token => c instanceof Token) ?? [],
    [loadedInputCurrency, loadedOutputCurrency]
  )
  const handleConfirmTokenWarning = useCallback(() => {
    setDismissTokenWarning(true)
  }, [])

  const { account, library, chainId } = useActiveWeb3React()
  const theme = useContext(ThemeContext)

  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalToggle()

  // for expert mode
  const toggleSettings = useToggleSettingsMenu()
  const [isExpertMode] = useExpertModeManager()

  // get custom setting values for user
  const [deadline] = useUserDeadline()
  const [allowedSlippage] = useUserSlippageTolerance()

  // swap state
  const { independentField, typedValue, recipient, limitValue, percentage } = useSwapState()
  const { v2Trade, currencyBalances, parsedAmount, currencies, inputError: swapInputError } = useDerivedSwapInfo()
  const { wrapType, execute: onWrap, inputError: wrapInputError } = useWrapCallback(
    currencies[Field.INPUT],
    currencies[Field.OUTPUT],
    typedValue
  )
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE
  const { address: recipientAddress } = useENSAddress(recipient)
  const toggledVersion = useToggledVersion()
  const trade = showWrap
    ? undefined
    : {
        [Version.v2]: v2Trade
      }[toggledVersion]

  const parsedAmounts = showWrap
    ? {
        [Field.INPUT]: parsedAmount,
        [Field.OUTPUT]: parsedAmount
      }
    : {
        [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
        [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount
      }

  usePreloadReserves(currencies.INPUT, currencies.OUTPUT)

  const {
    onSwitchTokens,
    onCurrencySelection,
    onUserInput,
    onChangeRecipient,
    onUserLimitInput
  } = useSwapActionHandlers()
  const isValid = !swapInputError
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value)
    },
    [onUserInput]
  )
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
    },
    [onUserInput]
  )

  // modal and loading
  const [{ showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash }, setSwapState] = useState<{
    showConfirm: boolean
    tradeToConfirm: Trade | undefined
    attemptingTxn: boolean
    swapErrorMessage: string | undefined
    txHash: string | undefined
  }>({
    showConfirm: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined
  })

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: showWrap
      ? parsedAmounts[independentField]?.toExact() ?? ''
      : parsedAmounts[dependentField]?.toSignificant(6) ?? ''
  }

  const route = trade?.route
  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] && currencies[Field.OUTPUT] && parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
  )
  const noRoute = !route

  // check whether the user has approved the router on the input token
  const [approval, approveCallback] = useApproveCallbackFromTrade(trade, allowedSlippage)

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true)
    }
  }, [approval, approvalSubmitted])

  const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(currencyBalances[Field.INPUT])
  // showing max and half button always
  //const atMaxAmountInput = Boolean(maxAmountInput && parsedAmounts[Field.INPUT]?.equalTo(maxAmountInput))

  // the callback to execute the swap
  const { callback: swapCallback, error: swapCallbackError } = useSwapCallback(
    trade,
    allowedSlippage,
    deadline,
    recipient
  )

  const { priceImpactWithoutFee } = computeTradePriceBreakdown(trade)

  const [swapType, setSwapType] = useState<string>('market')
  const [orderHistoryType, setOrderHistoryType] = useState<string>('open')
  const [limitChanged, setLimitChanged] = useState<boolean>(false)
  const [userLimit, setUserLimit] = useState<string>('')

  const query = useParsedQueryString()
  const isLimit = query?.type === 'limit' || swapType === 'limit'

  const inputCurrencyBalanceQuery = useCurrencyBalanceQuery(currencies.INPUT)
  const outputCurrencyBalanceQuery = useCurrencyBalanceQuery(currencies.OUTPUT)

  const handleSwap = useCallback(async () => {
    if (priceImpactWithoutFee && !confirmPriceImpactWithoutFee(priceImpactWithoutFee)) {
      return
    }
    if (!swapCallback) {
      return
    }

    setSwapState({ attemptingTxn: true, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: undefined })
    swapCallback()
      .then(async response => {
        setSwapState({
          attemptingTxn: false,
          tradeToConfirm,
          showConfirm,
          swapErrorMessage: undefined,
          txHash: response.hash
        })

        ReactGA.event({
          category: 'Swap',
          action:
            recipient === null
              ? 'Swap w/o Send'
              : (recipientAddress ?? recipient) === `account`
              ? 'Swap w/o Send + recipient'
              : 'Swap w/ Send',
          label: [trade?.inputAmount?.currency?.symbol, trade?.outputAmount?.currency?.symbol].join('/')
        })

        await ReadOnlyProvider.waitForTransaction(response.hash)

        await Promise.all([inputCurrencyBalanceQuery.refetch(), outputCurrencyBalanceQuery.refetch()])
      })
      .catch(error => {
        setSwapState({
          attemptingTxn: false,
          tradeToConfirm,
          showConfirm,
          swapErrorMessage: error.message,
          txHash: undefined
        })
      })
  }, [tradeToConfirm, priceImpactWithoutFee, recipient, recipientAddress, showConfirm, swapCallback, trade])

  const [limitOrderTransaction, setLimitOrderTransaction] = useState<any>(null)
  const handlePlaceOrder = async () => {
    if (account != null && isLimit) {
      const signer = await library?.getSigner(account).connectUnchecked()

      const handler = 'tombswap'

      const gelatoLimitOrders = new GelatoLimitOrders(
        chainId as ChainId,
        signer, // optional
        handler // optional
      )

      const inputCurrency = trade?.inputAmount.currency
      const outputCurrency = trade?.outputAmount.currency

      // Token to sell
      const inputToken =
        inputCurrency instanceof Token
          ? inputCurrency.address
            ? inputCurrency.address
            : '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'
          : inputCurrency === ETHER
          ? '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'
          : '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE' // FTM

      console.log('inputToken', inputToken)
      // Token to buy
      const outputToken =
        outputCurrency instanceof Token
          ? outputCurrency.address
            ? outputCurrency.address
            : '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'
          : outputCurrency === ETHER
          ? '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'
          : '' // TOMB

      // Amount to sell
      const inputAmount = ethers.utils.parseUnits(formattedAmounts.INPUT, currencies[Field.INPUT]?.decimals)

      // Minimum amount of outTOken which the users wants to receive back
      const minReturn = ethers.utils.parseUnits(
        showOutputValue(formattedAmounts[Field.OUTPUT]),
        currencies[Field.OUTPUT]?.decimals
      )

      try {
        const tx = await (
          await gelatoLimitOrders.submitLimitOrder(inputToken, outputToken, inputAmount, minReturn)
        ).wait()
        console.log('transaction', tx)
        setLimitOrderTransaction(tx)
      } catch (e) {
        let approved = await gelatoLimitOrders.approveTokenAmount(inputToken, MaxUint256).catch(error => {
          setSwapState({
            attemptingTxn: false,
            tradeToConfirm: undefined,
            showConfirm: true,
            swapErrorMessage: error.message,
            txHash: undefined
          })
          return false
        })

        if (approved) {
          await gelatoLimitOrders
            .submitLimitOrder(inputToken, outputToken, inputAmount, minReturn)
            .then(tx => {
              setLimitOrderTransaction(tx)
            })
            .catch(error => {
              setSwapState({
                attemptingTxn: false,
                tradeToConfirm: undefined,
                showConfirm: true,
                swapErrorMessage: error.message,
                txHash: undefined
              })
            })
        }
      }
    }
  }

  // errors
  const [showInverted, setShowInverted] = useState<boolean>(false)

  // warnings on slippage
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee)

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mode
  const showApproveFlow =
    !swapInputError &&
    (approval === ApprovalState.NOT_APPROVED ||
      approval === ApprovalState.PENDING ||
      (approvalSubmitted && approval === ApprovalState.APPROVED)) &&
    !(priceImpactSeverity > 3 && !isExpertMode)

  const handleConfirmDismiss = useCallback(() => {
    setSwapState({ showConfirm: false, tradeToConfirm, attemptingTxn, swapErrorMessage, txHash })
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, '')
    }
  }, [attemptingTxn, onUserInput, swapErrorMessage, tradeToConfirm, txHash])

  const handleAcceptChanges = useCallback(() => {
    setSwapState({ tradeToConfirm: trade, swapErrorMessage, txHash, attemptingTxn, showConfirm })
  }, [attemptingTxn, showConfirm, swapErrorMessage, trade, txHash])

  const handleInputSelect = useCallback(
    (inputCurrency: Currency) => {
      setApprovalSubmitted(false) // reset 2 step UI for approvals
      onCurrencySelection(Field.INPUT, inputCurrency)
    },
    [onCurrencySelection]
  )

  const handleMaxInput = useCallback(() => {
    maxAmountInput && onUserInput(Field.INPUT, maxAmountInput.toExact())
  }, [maxAmountInput, onUserInput])

  const handleHalfInput = useCallback(() => {
    let half = 0
    const maxAmount = maxAmountInput?.toExact()
    if (maxAmount) {
      half = parseFloat(maxAmount) / 2
    }
    onUserInput(Field.INPUT, `${half}`)
  }, [maxAmountInput, onUserInput])

  const handleOutputSelect = useCallback(
    (outputCurrency: Currency) => onCurrencySelection(Field.OUTPUT, outputCurrency),
    [onCurrencySelection]
  )

  const handleOrderLists = (e: any) => {
    const { type } = e.target.dataset
    setOrderHistoryType(type)
  }

  const history = useHistory()
  const handleSwapTypes = (value: string) => {
    setSwapType(value)
    history.replace({ search: `type=${value}` })
    if (!isLimit) {
      setLimitChanged(false)
    }
  }

  const handleLimitInput = (e: any) => {
    const { value } = e.target
    const { input } = e.target.dataset
    const executionPrice = trade?.executionPrice

    const formattedPrice = !showInverted ? executionPrice?.toSignificant(6) : executionPrice?.invert()?.toSignificant(6)
    if (input === 'value') {
      if (/^[0-9]*[.,]?[0-9]*$/.test(value)) {
        if (formattedPrice) {
          const limitDifference = parseFloat(value) - parseFloat(formattedPrice)
          const currentMarketDiff = (limitDifference / parseFloat(formattedPrice)) * 100
          setUserLimit(value)
          onUserLimitInput(Field.INPUT, value, `${currentMarketDiff.toFixed(4)}`)
          setLimitChanged(true)
        }
      } else {
        onUserLimitInput(Field.INPUT, '')
      }
    }

    if (input === 'percentage') {
      if (value) {
        const percentageToAmount = ((100 + parseFloat(value)) / 100) * parseFloat(`${formattedPrice}`)
        onUserLimitInput(Field.INPUT, `${percentageToAmount.toFixed(6)}`, value)
        setUserLimit(`${percentageToAmount.toFixed(6)}`)
        setLimitChanged(true)
      } else {
        onUserLimitInput(Field.INPUT, '', '')
        setUserLimit(`${formattedPrice}`)
      }
    }
  }

  const showInputValue = (current: string) => {
    if (parseFloat(userLimit)) {
      return formattedAmounts.INPUT
    }
    return current
  }

  const showOutputValue = (current: string) => {
    if (parseFloat(limitValue)) {
      if (!formattedAmounts.INPUT) {
        return ''
      }
      return `${parseFloat(formattedAmounts.INPUT) * parseFloat(limitValue)}`
    }
    return current
  }

  const shouldShowTokenWarning =
    urlLoadedTokens.length > 0 &&
    !Object.values(allTokens).find(token => urlLoadedTokens.includes(token)) &&
    !dismissTokenWarning

  return (
    <>
      <TokenWarningModal
        isOpen={shouldShowTokenWarning}
        tokens={urlLoadedTokens}
        onConfirm={handleConfirmTokenWarning}
      />

      <div
        className="flex flex-col md:flex-row"
        style={{ maxWidth: '820px', width: '100%', gap: '2rem', justifyContent: 'center' }}
      >
        <div style={{ maxWidth: '405px', width: '100%' }}>
          <SwapPoolTabs active={'swap'} />

          <AppBody>
            {/* Remove the component below and add Nav which shows "Market", "Limit" */}
            <MarketLimitsTabs isLimit={isLimit} handleSwapTypes={handleSwapTypes} active={swapType} />

            <Wrapper id="swap-page">
              <ConfirmSwapModal
                isOpen={showConfirm}
                trade={trade}
                originalTrade={tradeToConfirm}
                onAcceptChanges={handleAcceptChanges}
                attemptingTxn={attemptingTxn}
                txHash={txHash}
                recipient={recipient}
                allowedSlippage={allowedSlippage}
                onConfirm={handleSwap}
                swapErrorMessage={swapErrorMessage}
                onDismiss={handleConfirmDismiss}
              />

              <AutoColumn gap={'md'}>
                <CurrencyInputPanel
                  label={independentField === Field.OUTPUT && !showWrap && trade ? 'From (estimated)' : 'From'}
                  value={isLimit ? showInputValue(formattedAmounts[Field.INPUT]) : formattedAmounts[Field.INPUT]}
                  showMaxButton={true}
                  currency={currencies[Field.INPUT]}
                  onUserInput={handleTypeInput}
                  onMax={handleMaxInput}
                  onHalf={handleHalfInput}
                  onCurrencySelect={handleInputSelect}
                  otherCurrency={currencies[Field.OUTPUT]}
                  id="swap-currency-input"
                  isButton={false}
                />
                <AutoColumn justify="space-between">
                  <AutoRow justify={isExpertMode ? 'space-between' : 'center'} style={{ padding: '0 1rem' }}>
                    <ArrowWrapper clickable className="arrow-bg">
                      <ArrowDown
                        size="16"
                        onClick={() => {
                          setApprovalSubmitted(false) // reset 2 step UI for approvals
                          onSwitchTokens()
                        }}
                        color={currencies[Field.INPUT] && currencies[Field.OUTPUT] ? theme.tombText : theme.tombText}
                      />
                    </ArrowWrapper>
                    {recipient === null && !showWrap && isExpertMode ? (
                      <LinkStyledButton id="add-recipient-button" onClick={() => onChangeRecipient('')}>
                        + Add a send (optional)
                      </LinkStyledButton>
                    ) : null}
                  </AutoRow>
                </AutoColumn>
                <CurrencyInputPanel
                  value={isLimit ? showOutputValue(formattedAmounts[Field.OUTPUT]) : formattedAmounts[Field.OUTPUT]}
                  onUserInput={handleTypeOutput}
                  label={independentField === Field.INPUT && !showWrap && trade ? 'To (estimated)' : 'To'}
                  showMaxButton={false}
                  currency={currencies[Field.OUTPUT]}
                  onCurrencySelect={handleOutputSelect}
                  otherCurrency={currencies[Field.INPUT]}
                  id="swap-currency-output"
                  isButton={true}
                  disableInput={isLimit}
                />

                {recipient !== null && !showWrap ? (
                  <>
                    <AutoRow justify="space-between" style={{ padding: '0 1rem' }}>
                      <ArrowWrapper clickable={false}>
                        <ArrowDown size="16" color={theme.text2} />
                      </ArrowWrapper>
                      <LinkStyledButton
                        style={{ color: 'red' }}
                        id="remove-recipient-button"
                        onClick={() => onChangeRecipient(null)}
                      >
                        - Remove send
                      </LinkStyledButton>
                    </AutoRow>
                    <AddressInputPanel id="recipient" value={recipient} onChange={onChangeRecipient} />
                  </>
                ) : null}

                {showWrap ? null : (
                  <Card padding={'.25rem .75rem 0 .75rem'} borderRadius={'20px'}>
                    <AutoColumn gap="4px">
                      {Boolean(trade) && !isLimit && (
                        <RowBetween align="center">
                          <Text fontWeight={500} fontSize={14} color={theme.text2}>
                            {t('price')}
                          </Text>
                          <TradePrice
                            price={trade?.executionPrice}
                            showInverted={showInverted}
                            setShowInverted={setShowInverted}
                          />
                        </RowBetween>
                      )}
                      {allowedSlippage !== INITIAL_ALLOWED_SLIPPAGE && (
                        <RowBetween align="center">
                          <ClickableText fontWeight={500} fontSize={14} color={theme.text2} onClick={toggleSettings}>
                            Slippage Tolerance
                          </ClickableText>
                          <ClickableText fontWeight={500} fontSize={14} color={theme.text2} onClick={toggleSettings}>
                            {allowedSlippage / 100}%
                          </ClickableText>
                        </RowBetween>
                      )}
                    </AutoColumn>
                  </Card>
                )}
              </AutoColumn>

              {/* Add limit price details here */}
              {isLimit && (
                <LimitPriceDetails
                  trade={trade}
                  setShowInverted={setShowInverted}
                  showInverted={showInverted}
                  userLimit={limitValue}
                  limitChanged={limitChanged}
                  inputValue={formattedAmounts?.INPUT}
                  limitPercentage={percentage}
                  onUserInput={handleLimitInput}
                />
              )}

              <BottomGrouping>
                {!account ? (
                  <ButtonLight onClick={toggleWalletModal} className="btn">
                    Connect Wallet
                  </ButtonLight>
                ) : showWrap ? (
                  <ButtonPrimary disabled={Boolean(wrapInputError)} className="btn" onClick={onWrap}>
                    {wrapInputError ??
                      (wrapType === WrapType.WRAP ? 'Wrap' : wrapType === WrapType.UNWRAP ? 'Unwrap' : null)}
                  </ButtonPrimary>
                ) : noRoute && userHasSpecifiedInputOutput ? (
                  <GreyCard style={{ textAlign: 'center' }}>
                    <TYPE.main mb="4px">{t('insufficientLiquidityForThisTrade')}</TYPE.main>
                  </GreyCard>
                ) : showApproveFlow ? (
                  <RowBetween>
                    <ButtonConfirmed
                      onClick={approveCallback}
                      disabled={approval !== ApprovalState.NOT_APPROVED || approvalSubmitted}
                      width="48%"
                      altDisabledStyle={approval === ApprovalState.PENDING} // show solid button while waiting
                      confirmed={approval === ApprovalState.APPROVED}
                      className="btn"
                    >
                      {approval === ApprovalState.PENDING ? (
                        <AutoRow gap="6px" justify="center">
                          Approving <Loader stroke="white" />
                        </AutoRow>
                      ) : approvalSubmitted && approval === ApprovalState.APPROVED ? (
                        'Approved'
                      ) : (
                        'Approve ' + currencies[Field.INPUT]?.symbol
                      )}
                    </ButtonConfirmed>
                    <ButtonError
                      onClick={() => {
                        if (isExpertMode) {
                          handleSwap()
                        } else {
                          setSwapState({
                            tradeToConfirm: trade,
                            attemptingTxn: false,
                            swapErrorMessage: undefined,
                            showConfirm: true,
                            txHash: undefined
                          })
                        }
                      }}
                      width="48%"
                      id="swap-button"
                      className="btn"
                      disabled={
                        !isValid || approval !== ApprovalState.APPROVED || (priceImpactSeverity > 3 && !isExpertMode)
                      }
                      error={isValid && priceImpactSeverity > 2}
                    >
                      <Text fontSize={16} fontWeight={500}>
                        {priceImpactSeverity > 3 && !isExpertMode
                          ? `Price Impact High`
                          : `Swap${priceImpactSeverity > 2 ? ' Anyway' : ''}`}
                      </Text>
                    </ButtonError>
                  </RowBetween>
                ) : (
                  <>
                    {isLimit ? (
                      <ButtonError className="btn" onClick={() => handlePlaceOrder()} id="swap-button">
                        <Text fontSize={20} fontWeight={500} color={theme.tombText}>
                          Place Order
                        </Text>
                      </ButtonError>
                    ) : (
                      <ButtonError
                        className="btn"
                        onClick={() => {
                          if (isExpertMode) {
                            handleSwap()
                          } else {
                            setSwapState({
                              tradeToConfirm: trade,
                              attemptingTxn: false,
                              swapErrorMessage: undefined,
                              showConfirm: true,
                              txHash: undefined
                            })
                          }
                        }}
                        id="swap-button"
                        disabled={!isValid || (priceImpactSeverity > 3 && !isExpertMode) || !!swapCallbackError}
                        error={isValid && priceImpactSeverity > 2 && !swapCallbackError}
                      >
                        <Text fontSize={20} fontWeight={500}>
                          {swapInputError
                            ? swapInputError
                            : priceImpactSeverity > 3 && !isExpertMode
                            ? `Price Impact Too High`
                            : `Swap${priceImpactSeverity > 2 ? ' Anyway' : ''}`}
                        </Text>
                      </ButtonError>
                    )}
                  </>
                )}
                {showApproveFlow && <ProgressSteps steps={[approval === ApprovalState.APPROVED]} />}
                {isExpertMode && swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
              </BottomGrouping>
            </Wrapper>
          </AppBody>

          {account && <AdvancedSwapDetailsDropdown trade={trade} isLimit={limitValue ? true : false} />}

          {isLimit && (
            <AutoColumn>
              <p className="text-center text-sm mt-6 tomb-text" style={{ maxWidth: '405px' }}>
                * Disclaimer: Limit Orders on Tomb Swap are provided by Gelato, a 3rd party protocol and should be
                considered in beta. DYOR and use at your own risk. Tomb Swap is not responsible. More info can be found
                there.
              </p>
            </AutoColumn>
          )}
        </div>

        {isLimit && (
          <div style={{ maxWidth: '405px', width: '100%' }}>
            <div style={{ height: '60px' }}></div>
            <AppBody>
              <OrdersPanel
                transaction={limitOrderTransaction}
                currentOrderListType={orderHistoryType}
                handleOrderLists={handleOrderLists}
                setSwapState={setSwapState}
                limitPerPage={11}
              ></OrdersPanel>
            </AppBody>
          </div>
        )}
      </div>
    </>
  )
}

//465: {betterTradeLinkVersion && <BetterTradeLink version={betterTradeLinkVersion} />}
