import React from 'react'
import { AutoColumn } from '../Column'
import styled from 'styled-components'
import invertIcon from '../../assets/img/arrow-right-arrow-left-solid.svg'

const LimitsContainer = styled.div`
  background: linear-gradient(360deg, rgba(30, 0, 56, 0.45) 0%, rgba(22, 0, 44, 0.6) 56.05%);
  border: 1px solid #a000fa;
  border-radius: 20px;
`

export default function LimitPriceDetails(props: any) {
  const { showInverted, setShowInverted, trade, userLimit, inputValue, limitChanged, limitPercentage } = props
  const executionPrice = trade?.executionPrice

  const formattedPrice = !showInverted ? executionPrice?.toSignificant(6) : executionPrice?.invert()?.toSignificant(6)

  let invertedShowValue = limitChanged ? (parseFloat(inputValue) / parseFloat(userLimit)).toFixed(6) : formattedPrice
  let showValue = showInverted ? invertedShowValue : limitChanged ? userLimit : formattedPrice

  const label = !showInverted
    ? `${executionPrice?.quoteCurrency?.symbol} per ${executionPrice?.baseCurrency?.symbol}`
    : `${executionPrice?.baseCurrency?.symbol} per ${executionPrice?.quoteCurrency?.symbol}`

  const coin = !showInverted ? executionPrice?.quoteCurrency?.symbol : executionPrice?.baseCurrency?.symbol

  return (
    <AutoColumn>
      <LimitsContainer className="py-4">
        <h1 className="text-base font-medium pl-4">Limit Price</h1>
        <div className="flex justify-between p-4 border-b border-inherit">
          <span className="justify-between items-center flex">
            {trade && (
              <div className="flex justify-between gap-x-px	">
                <span className="mr-8">{label}</span>
                <img
                  className="mr-2"
                  style={{ height: '23px' }}
                  src={invertIcon}
                  alt={''}
                  width="18"
                  height="14"
                  onClick={() => setShowInverted(!showInverted)}
                />
                <span>Invert</span>
              </div>
            )}
          </span>
          <input
            className="token-amount-input token-amount-value font-bold justify-self-end bg-transparent	text-inherit w-28 text-right"
            inputMode="decimal"
            autoComplete="off"
            autoCorrect="off"
            type="text"
            pattern="^[0-9]*[.,]?[0-9]*$"
            placeholder="0.0"
            minLength={1}
            maxLength={79}
            spellCheck="false"
            data-input="value"
            value={showValue || ''}
            onChange={e => props.onUserInput(e)}
          />
        </div>
        <div className="flex justify-between p-4 border-b border-inherit">
          <span>Above Market</span>
          <span className="font-bold">
            {/* {limitChanged ? (currentMarketDiff * 100).toFixed(4) : '0.00'} */}
            <input
              className="token-amount-input token-amount-value font-bold justify-self-end bg-transparent	text-inherit w-28 text-right"
              inputMode="decimal"
              autoComplete="off"
              autoCorrect="off"
              type="text"
              pattern="^[0-9]*[.,]?[0-9]*$"
              placeholder="0.0"
              minLength={1}
              maxLength={79}
              spellCheck="false"
              value={limitPercentage || ''}
              data-input={'percentage'}
              onChange={e => {
                props.onUserInput(e)
              }}
            />
            %
          </span>
        </div>
        <div className="flex justify-between p-4 pb-0">
          <span>Market</span>
          <span>
            {formattedPrice}
            <span> {coin}</span>
          </span>
        </div>
      </LimitsContainer>
    </AutoColumn>
  )
}
