import React from 'react'
import styled from 'styled-components'
import { darken } from 'polished'
import { useTranslation } from 'react-i18next'
import { NavLink, Link as HistoryLink } from 'react-router-dom'
import { ButtonEmpty } from '../Button'

import { ArrowLeft } from 'react-feather'
import { RowBetween } from '../Row'
import QuestionHelper from '../QuestionHelper'

const Tabs = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  border-radius: 16px;
  justify-content: space-evenly;
  background: linear-gradient(360deg, rgba(30, 0, 56, 0.45) 0%, rgba(22, 0, 44, 0.6) 56.05%);
  border: 1px solid #a000fa;
  max-width: 350px;
`

const activeClassName = 'ACTIVE'
const activeClassName2 = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text3};
  font-size: 1rem;
  width: 50%;

  &.${activeClassName} {
    border-radius: 12px;
    font-weight: 500;
    color: ${({ theme }) => theme.tombText};
  }

  :active,
  :hover,
  :focus {
    color: ${({ theme }) => theme.tombText};
  }
`

const StyledNavLink2 = styled(ButtonEmpty).attrs({
  activeClassName
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.tombText};
  font-size: 1rem;
  width: 50%;

  &.${activeClassName2} {
    font-size: 0.825rem;
    background: none;
    border: none;
    border-bottom: 3px solid rgba(148, 1, 251, 1);
    border-radius: 0px;
    font-weight: 500;
    color: ${({ theme }) => theme.tombText};
  }
  &.${activeClassName2}:hover {
    background: none;
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.01, theme.tombText)};
  }
`

const ActiveText = styled.div`
  font-weight: 500;
  font-size: 20px;
`

const StyledArrowLeft = styled(ArrowLeft)`
  color: ${({ theme }) => theme.text1};
`

export function SwapPoolTabs({ active }: { active: 'swap' | 'pool' }) {
  const { t } = useTranslation()
  return (
    <Tabs style={{ marginBottom: '20px', width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
      <StyledNavLink className={`h-10 text-sm ${(active === 'swap') ? 'btn' : ''}`} id={`swap-nav-link`} to={'/swap'} isActive={() => active === 'swap'}>
        {t('swap')}
      </StyledNavLink>
      <StyledNavLink className={`h-10 text-sm ${(active === 'pool') ? 'btn' : ''}`} id={`pool-nav-link`} to={'/pool'} isActive={() => active === 'pool'}>
        {t('pool')}
      </StyledNavLink>
    </Tabs>
  )
}

// export function MarketLimitsTabs({ active }: { active: 'market' | 'limit' }) {
//   const { t } = useTranslation()
//   return (
//     <Tabs className='mx-8 h-8' style={{ marginBottom: '20px', borderRadius: '0.5rem'}}>
//       <StyledNavLink className='h-8' id={`market-nav-link`} to={'/swap'} isActive={() => active === 'market'}>
//         {t('Market')}
//       </StyledNavLink>
//       <StyledNavLink className='h-8' id={`limits-nav-link`} to={'/pool'} isActive={() => active === 'limit'}>
//         {t('Limits')}
//       </StyledNavLink>
//     </Tabs>
//   )
// }

export function MarketLimitsTabs({ isLimit, active, handleSwapTypes }: { isLimit:boolean, active: string, handleSwapTypes: any }) {
  const { t } = useTranslation()
  if(isLimit){
    active = 'limit';
  }
  return (
    <Tabs
      className="h-8 w-full	"
      style={{ marginBottom: '20px', borderRadius: '0.5rem', border: 'none', maxWidth: '405px' }}
    >
      <StyledNavLink2
        className={`h-8 ${active === 'market' ? 'ACTIVE' : ''}`}
        onClick={() => handleSwapTypes('market')}
      >
        {t('Market')}
      </StyledNavLink2>
      <StyledNavLink2 className={`h-8 ${active === 'limit' ? 'ACTIVE' : ''}`} onClick={() => handleSwapTypes('limit')}>
        {t('Limits')}
      </StyledNavLink2>
    </Tabs>
  )
}

export function FindPoolTabs() {
  return (
    <Tabs style={{ marginBottom: '1rem' }}>
      <RowBetween style={{ padding: '1rem' }}>
        <HistoryLink to="/pool">
          <StyledArrowLeft />
        </HistoryLink>
        <ActiveText>Import Pool</ActiveText>
        <QuestionHelper text={"Use this tool to find pairs that don't automatically appear in the interface."} />
      </RowBetween>
    </Tabs>
  )
}

export function AddRemoveTabs({ adding }: { adding: boolean }) {
  return (
    <Tabs style={{ marginBottom: '1rem' }}>
      <RowBetween style={{ padding: '1rem' }}>
        <HistoryLink to="/pool">
          <StyledArrowLeft />
        </HistoryLink>
        <ActiveText>{adding ? 'Add' : 'Remove'} Liquidity</ActiveText>
        <QuestionHelper
          text={
            adding
              ? 'When you add liquidity, you are given pool tokens representing your position. These tokens automatically earn fees proportional to your share of the pool, and can be redeemed at any time.'
              : 'Removing pool tokens converts your position back into underlying tokens at the current rate, proportional to your share of the pool. Accrued fees are included in the amounts you receive.'
          }
        />
      </RowBetween>
    </Tabs>
  )
}
