import React from 'react'
import styled from 'styled-components'
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/outline'
import { useHistory } from 'react-router-dom'
import New from '../../../assets/svg/New.svg'

const MoreMenuBg = styled.div<{ width?: string }>`
  ${({ theme }) => theme.flexRowNoWrap}
  position: relative;
  width: ${({ width }) => (width ? width : 'max-content')};
  background: linear-gradient(360deg, rgba(30, 0, 56, 1) 0%, rgba(22, 0, 44, 1) 56.05%);
  border: 1px solid #a000fa;
  border-radius: 20px;
  padding: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`

const NewIcon = styled.div`
  position: absolute;
  top: -15px;
  right: -10px;
  z-index: 999;
  width: 33px;
`

export type MenuItems = {
  name: string
  current?: boolean
  isNew?: boolean
  href?: string
  isExternal?: boolean
}

export function DropDownMenuItems({ items, width }: { items: MenuItems[]; width?: string }) {
  return (
    <MoreMenuBg width={width}>
      <div className="py-2 px-3">
        {items.map(item => {
          return (
            <div
              key={item.name}
              className={`relative
                    ${item.current ? 'nav-selected textShadowLgSelected my-2' : 'hoverEffect my-2'}   
                  `}
            >
              {item.isNew && (
                <NewIcon>
                  <img src={New} alt="" />
                </NewIcon>
              )}
              <a href={item.href} aria-current={item.current ? 'page' : undefined}>
                <span className="menu-item-style">{item.name}</span>
              </a>
            </div>
          )
        })}
      </div>
    </MoreMenuBg>
  )
}

export const DropDownMenu = ({
  setToggleMoreMenu,
  toggleMoreMenu,
  handleMouseLeave,
  dropDownMenuItems,
  parentItem,
  width
}: {
  setToggleMoreMenu: Function
  toggleMoreMenu: string
  handleMouseLeave: Function
  dropDownMenuItems: MenuItems[]
  parentItem: MenuItems
  width?: string
}) => {
  const history = useHistory()

  const navigate = (item: MenuItems) => {
    return item?.href && item?.isExternal
      ? window.location.replace(item.href)
      : item.href
      ? history.replace(item.href)
      : null
  }

  return (
    <div
      className="relative hoverEffect"
      onMouseEnter={() => setToggleMoreMenu(parentItem.name)}
      onMouseLeave={e => handleMouseLeave(e)}
    >
      <button
        className="flex flex-row items-center w-full relative"
        onClick={() => {
          setToggleMoreMenu(!toggleMoreMenu)
          navigate(parentItem)
        }}
      >
        {/* <NewIcon> <img src={New} alt="" /></NewIcon> */}
        <span style={{ fontFamily: 'Poppins' }} className="menu-item-style mr-1">
          {parentItem?.name}
        </span>
        {toggleMoreMenu === parentItem.name ? (
          <ChevronUpIcon style={{ width: '16px', marginTop: '2px' }} />
        ) : (
          <ChevronDownIcon style={{ width: '16px', marginTop: '2px' }} />
        )}
      </button>
      <div className={`absolute ${toggleMoreMenu === parentItem.name ? 'inline-block' : 'hidden'}`}>
        <DropDownMenuItems items={dropDownMenuItems} width={width} />
      </div>
    </div>
  )
}
