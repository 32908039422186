import { ChainId, JSBI, Percent, Token, WETH } from 'tombswap-sdk'
import { AbstractConnector } from '@web3-react/abstract-connector'
import { routerv2 } from '../tombswap_address.json'

import { injected, walletconnect } from '../connectors'
//fortmatic, portis, walletconnect, walletlink, lattice
export const ROUTER_ADDRESS: { [key: string]: string } = {
  [ChainId.MAINNET]: routerv2,
  [ChainId.FTMTESTNET]: routerv2
}

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: Token[]
}

export const USDC = new Token(ChainId.MAINNET, '0x04068da6c83afcfa0e13ba15a6696662335d5b75', 6, 'USDC', 'USD//C')
export const TOMB = new Token(ChainId.MAINNET, '0x6c021Ae822BEa943b2E66552bDe1D2696a53fbB7', 18, 'TOMB', 'TOMB')
export const TSHARE = new Token(ChainId.MAINNET, '0x4cdf39285d7ca8eb3f090fda0c069ba5f4145b37', 18, 'TSHARE', 'TSHARE')
export const TBOND = new Token(ChainId.MAINNET, '0x24248cd1747348bdc971a5395f4b3cd7fee94ea0', 18, 'TBOND', 'TBOND')
export const MAI = new Token(ChainId.MAINNET, '0xfb98b335551a418cd0737375a2ea0ded62ea213b', 18, 'MAI', 'MAI')
export const FUSDT = new Token(ChainId.MAINNET, '0x049d68029688eabf473097a2fc38ef61633a3c7a', 6, 'fUSDT', 'Frapped USDT')
export const MIM = new Token(ChainId.MAINNET, '0x82f0b8b456c1a451378467398982d4834b6829c1', 18, 'MIM', 'MIM')
export const FUSD = new Token(ChainId.MAINNET, '0xad84341756bf337f5a0164515b1f6f993d194e1f', 18, 'FUSD', 'FUSD')
export const DAI = new Token(ChainId.MAINNET, '0x8d11ec38a3eb5e956b052f67da8bdc9bef8abf3e', 18, 'DAI', 'DAI')
export const LIF3 = new Token(ChainId.MAINNET, '0xbf60e7414ef09026733c1e7de72e7393888c64da', 18, 'LIF3', 'LIF3')
export const LSHARE = new Token(ChainId.MAINNET, '0xcbe0ca46399af916784cadf5bcc3aed2052d6c45', 18, 'LSHARE', 'LSHARE')
export const WBTC = new Token(ChainId.MAINNET, '0x321162Cd933E2Be498Cd2267a90534A804051b11', 8, 'WBTC', 'BTC')
export const L3USD = new Token(ChainId.MAINNET, '0x5f0456F728E2D59028b4f5B8Ad8C604100724C6A', 18, 'L3USD', 'L3USD')


const WDEV_ONLY: ChainTokenList = {
  [ChainId.MAINNET]: [WETH[ChainId.MAINNET]],
  [ChainId.FTMTESTNET]: [WETH[ChainId.FTMTESTNET]],
  [ChainId.ETHMAINNET]: [WETH[ChainId.ETHMAINNET]],
  [ChainId.BSC]: [WETH[ChainId.BSC]]
}

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WDEV_ONLY,
  [ChainId.MAINNET]: [...WDEV_ONLY[ChainId.MAINNET], TOMB, TSHARE, TBOND, MAI, MIM, USDC, FUSD, DAI, LIF3, LSHARE, WBTC, L3USD]
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  // [ChainId.MAINNET]: {
  //   [AMPL.address]: [DAI, WETH[ChainId.MAINNET]]
  // }
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  ...WDEV_ONLY,
  [ChainId.MAINNET]: [
    // ...WDEV_ONLY[ChainId.MAINNET],
    TOMB,
    TSHARE,
    USDC,
    /*  MAI,
    BASED */
  ]
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WDEV_ONLY,
  [ChainId.MAINNET]: [...WDEV_ONLY[ChainId.MAINNET], TOMB, TSHARE, TBOND, MAI, USDC, FUSDT, MIM, FUSD, LIF3, LSHARE, WBTC, L3USD]
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  // [ChainId.MAINNET]: [
  //   [
  //     new Token(ChainId.MAINNET, '0x5d3a536E4D6DbD6114cc1Ead35777bAB948E3643', 8, 'cDAI', 'Compound Dai'),
  //     new Token(ChainId.MAINNET, '0x39AA39c021dfbaE8faC545936693aC917d5E7563', 8, 'cUSDC', 'Compound USD Coin')
  //   ],
  //   [USDC, USDT],
  //   [DAI, USDT]
  // ]
}

export interface WalletInfo {
  connector?: AbstractConnector
  name: string
  iconName: string
  description: string
  href: string | null
  color: string
  primary?: true
  mobile?: true
  mobileOnly?: true
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  /* INJECTED: {
    connector: injected,
    name: 'Injected',
    iconName: 'arrow-right.svg',
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true
  },*/
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    iconName: 'metamask.png',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D'
  },
  WALLET_CONNECT: {
    connector: walletconnect,
    name: 'WalletConnect',
    iconName: 'walletConnectIcon.svg',
    description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    href: null,
    color: '#4196FC',
    mobile: true
  } /*
  LATTICE: {
    connector: lattice,
    name: 'Lattice',
    iconName: 'gridPlusWallet.png',
    description: 'Connect to GridPlus Wallet.',
    href: null,
    color: '#40a9ff',
    mobile: true
  },
  WALLET_LINK: {
    connector: walletlink,
    name: 'Coinbase Wallet',
    iconName: 'coinbaseWalletIcon.svg',
    description: 'Use Coinbase Wallet app on mobile device',
    href: null,
    color: '#315CF5'
  },
  COINBASE_LINK: {
    name: 'Open in Coinbase Wallet',
    iconName: 'coinbaseWalletIcon.svg',
    description: 'Open in Coinbase Wallet app.',
    href: 'https://go.cb-w.com/mtUDhEZPy1',
    color: '#315CF5',
    mobile: true,
    mobileOnly: true
  },
  FORTMATIC: {
    connector: fortmatic,
    name: 'Fortmatic',
    iconName: 'fortmaticIcon.png',
    description: 'Login using Fortmatic hosted wallet',
    href: null,
    color: '#6748FF',
    mobile: true
  },
  Portis: {
    connector: portis,
    name: 'Portis',
    iconName: 'portisIcon.png',
    description: 'Login using Portis hosted wallet',
    href: null,
    color: '#4A6C9B',
    mobile: true
  }*/
}

export const NetworkContextName = 'NETWORK'

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 50
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much ETHER so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 ETHER
export const BETTER_TRADE_LINK_THRESHOLD = new Percent(JSBI.BigInt(75), JSBI.BigInt(10000))

//export const SWAP_SUBGRAPH_URL = 'https://api.thegraph.com/subgraphs/name/jamjomjim/tomb-finance'
export const SWAP_SUBGRAPH_URL = 'https://graph-node.tomb.com/subgraphs/name/tombswap-subgraph'

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'
