import React, { useState } from 'react'
import { ArrowRightIcon } from '@heroicons/react/outline'
import { Order } from '@gelatonetwork/limit-orders-lib'
import { useCurrency } from '../../hooks/Tokens'
import { ethers } from 'ethers'
import CurrencyLogo from '../CurrencyLogo'
import { Currency } from 'tombswap-sdk'
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/outline'
import { tryParseAmount } from '../../state/swap/hooks'
/*import { useTradeExactIn } from '../../hooks/Trades' */

const isNativeToken = (address: string) => {
  return address.toLowerCase() === '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'
}

export const OrderList = ({
  type,
  order,
  cancelOrder
}: {
  type?: 'open' | 'past'
  order: Order
  cancelOrder?: any
}) => {
  const inputTokenDetails = useCurrency(isNativeToken(order.inputToken) ? 'ETH' : order.inputToken)
  const outputTokenDetails = useCurrency(isNativeToken(order.outputToken) ? 'ETH' : order.outputToken)
  const [isOpen, setIsOpen] = useState(false)
  const limitInputAmount = ethers.utils.formatUnits(order.inputAmount, inputTokenDetails?.decimals || '18')
  const limitOrderAdjustedMinReturn = ethers.utils.formatUnits(
    order.adjustedMinReturn,
    inputTokenDetails?.decimals || '18'
  )
  const limitOrderMinReturn = ethers.utils.formatUnits(order.minReturn, inputTokenDetails?.decimals || '18')
  const inputTokenName = inputTokenDetails?.name
    ? inputTokenDetails?.name
    : isNativeToken(order.inputToken)
    ? Currency.ETHER.symbol || ''
    : 'Unknown'
  const outputTokenName = outputTokenDetails?.name
    ? outputTokenDetails.name
    : isNativeToken(order.outputToken)
    ? Currency.ETHER.symbol || ''
    : 'Unknown'
  const combineTokenTitle = inputTokenName + outputTokenName

  const [orderCancelling, setOrderCancelling] = useState<boolean>(false)

  const handleCancelOrder = async (order: Order) => {
    setOrderCancelling(true)
    await cancelOrder(order)
    setOrderCancelling(false)
  }

  const inputParsedAmount = tryParseAmount(
    ethers.utils.formatUnits(order.inputAmount, inputTokenDetails?.decimals || '18'),
    useCurrency(order.inputToken) ?? undefined
  )

  const isPastOrderTab = type === 'past'
  /*
  const outputParsedAmount = tryParseAmount(
    ethers.utils.formatUnits(order.adjustedMinReturn, outputTokenDetails?.decimals || '18'),
    useCurrency(order.outputToken) ?? undefined
  )
  const executedTrade = useTradeExactIn(parsedAmount, outputTokenDetails ?? undefined)
  const executionPrice = executedTrade?.executionPrice
  console.log(executionPrice) */

  const limitPrice = {
    amount: parseFloat(limitOrderAdjustedMinReturn) / parseFloat(limitInputAmount),
    revert: () => {
      return parseFloat(limitInputAmount) / parseFloat(limitOrderAdjustedMinReturn)
    }
  }

  return (
    <div>
      <div
        style={{ fontSize: combineTokenTitle.length > 15 ? '0.65rem' : '' }}
        className={`flex justify-between cursor-pointer ${combineTokenTitle.length > 15 ? 'text-xs' : ''}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex mt-4 pr-4">
          <span className="text-xs md:font-bold flex">
            <CurrencyLogo currency={inputTokenDetails ?? undefined} style={{ marginRight: 8 }} />
            {inputTokenName}
          </span>
          <ArrowRightIcon className="mx-4" style={{ width: '15px' }} />
          <span className="text-xs md:font-bold flex break-all">
            <CurrencyLogo currency={outputTokenDetails ?? undefined} style={{ marginRight: 8 }} />
            {outputTokenName}
          </span>
        </div>
        <div className="flex flex-start">
          <span className="text-xs md:font-bold mt-4 justify-right pr-2">{limitPrice.amount.toFixed(4)}</span>
        </div>
        <div className="flex flex-start">
          <span className="text-xs md:font-bold mt-4 justify-right pr-2">
            {inputParsedAmount?.toExact()} {inputTokenName}
          </span>
          {isOpen ? (
            <ChevronUpIcon className="mt-4" style={{ width: '16px' }} />
          ) : (
            <ChevronDownIcon className="mt-4" style={{ width: '16px' }} />
          )}
        </div>
      </div>

      <div className={`${isOpen ? '' : 'hidden'}`}>
        <div>
          <div className="text-xs p-4 md:pl-8 md:pr-8">
            <div className="flex justify-between mb-3">
              <span className="font-medium font-bold">From</span>
              <span>
                {limitInputAmount} {inputTokenName}
              </span>
            </div>

            <div className="flex justify-between mb-3">
              <span className="font-medium font-bold">To</span>
              <span>
                {parseFloat(limitOrderAdjustedMinReturn).toFixed(4)} {outputTokenName}
              </span>
            </div>

            <div className="flex justify-between mb-3">
              <span className="font-medium font-bold">Minimum Received</span>
              <span>
                {parseFloat(limitOrderMinReturn).toFixed(4)} {outputTokenName}
              </span>
            </div>

            <div className="flex justify-between mb-1">
              <span className="font-medium font-bold">Limit Price</span>
              <span>
                1 {inputTokenName} = {limitPrice.amount.toFixed(4)} {outputTokenName}
              </span>
            </div>

            <div className="flex justify-end mb-3">
              <span>
                1 {outputTokenName} = {limitPrice.revert().toFixed(4)} {inputTokenName}
              </span>
            </div>

            <div className="flex justify-between mb-2">
              <span className="font-medium font-bold">Date Submitted</span>
              <span>{getFormattedTime(order.createdAt)}</span>
            </div>
          </div>
        </div>

        <div className={`flex mx-4 mb-8 ${isPastOrderTab ? 'justify-center' : 'justify-between'}`}>
          <a
            rel="noopener noreferrer"
            target={'_blank'}
            href={`https://ftmscan.com/tx/${order.createdTxHash}`}
            className="btn w-36 h-8 font-bold text-center pt-1 cursor-pointer"
          >
            FTMSCAN
          </a>
          {type === 'open' && (
            <div
              className={`btn w-36 h-8 font-bold text-center pt-1 cursor-pointer ${
                orderCancelling ? 'cursor-not-allowed' : ''
              }`}
              onClick={() => handleCancelOrder(order)}
            >
              {orderCancelling ? 'CANCELLING' : 'CANCEL ORDER'}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const getFormattedTime = (time: string, locale = 'en-US') => {
  const parsedTime = new Date(parseInt(time + '000'))
  const formattedDate = parsedTime.toLocaleDateString(locale, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  })
  const formattedTime = parsedTime.toLocaleTimeString(locale)

  return `${formattedTime} ${formattedDate}`
}
