import { Pair, Token } from 'tombswap-sdk'
import { useQuery } from 'react-query'

import { abi as IUniswapV2PairABI } from '@uniswap/v2-core/build/IUniswapV2Pair.json'

import { multicallFailSafe } from '../../utils/multicall'
import { ReadOnlyProvider } from 'connectors'

export const usePairsReservesQuery = (
  tokensMap: Record<
    string,
    {
      tokenA: Token
      tokenB: Token
    }
  >
) => {
  const tokens = Object.values(tokensMap).reduce<Array<[Token, Token]>>((acc, { tokenA, tokenB }) => {
    const address = tokenA && tokenB && !tokenA.equals(tokenB) ? Pair.getAddress(tokenA, tokenB) : undefined

    if (address && tokenA && tokenB) {
      acc.push([tokenA, tokenB])
    }

    return acc
  }, [])

  const pairAddresses = tokens.reduce<Record<string, { tokenA: Token | undefined; tokenB: Token | undefined }>>(
    (acc, [tokenA, tokenB]) => {
      const address = tokenA && tokenB && !tokenA.equals(tokenB) ? Pair.getAddress(tokenA, tokenB) : undefined

      if (address) {
        acc[address] = { tokenA, tokenB }
      }

      return acc
    },
    {}
  )

  return useQuery(
    ['pairs-reserves', ...Object.keys(pairAddresses)],
    async () => {
      const addressesWithLiquidity = Object.keys(pairAddresses).filter(pairAddress => Boolean(pairAddress))

      const calls = addressesWithLiquidity.map(pairAddress => ({
        address: pairAddress as string,
        name: 'getReserves'
      }))
      const reserves = await multicallFailSafe(IUniswapV2PairABI, calls, ReadOnlyProvider as any)

      const result = reserves
        .map((reserve: any, index: number) => {
          return reserve ? { ...reserve, pairAddress: addressesWithLiquidity[index] } : null
        })
        .filter((reserve: any) => Boolean(reserve))

      return result
    },
    { enabled: Boolean(Object.keys(pairAddresses).length) }
  )
}
