import { getContract } from 'utils'
import MultiCallAbi from 'constants/multicall/abi.json'
import { ethers } from 'ethers'
import { MULTICALL_NETWORKS } from 'constants/multicall'
import { ChainId } from 'tombswap-sdk'

export interface Call {
  address: string // Address of the contract
  name: string // Function name on the contract (example: balanceOf)
  params?: any[] // Function params
}

export const getMulticallContract: any = (signer: any) => {
  return getContract(MULTICALL_NETWORKS[ChainId.MAINNET], MultiCallAbi, signer)
}

export const multicall = async <T = any>(
  abi: any[],
  calls: Call[],
  signer?: ethers.Signer | ethers.providers.Provider
): Promise<T> => {
  try {
    const multi = getMulticallContract(signer)
    const itf = new ethers.utils.Interface(abi)

    const calldata = calls.map(call => [call.address.toLowerCase(), itf.encodeFunctionData(call.name, call.params)])
    const { returnData } = await multi.aggregate(calldata, { blockTag: 'pending' })

    const res = returnData.map((call: any, i: any) => itf.decodeFunctionResult(calls[i].name, call))

    return res
  } catch (error) {
    throw new Error(error)
  }
}

export const multicallFailSafe = async <T = any>(
  abi: any[],
  calls: Call[],
  signer?: ethers.Signer | ethers.providers.Provider,
  chainId?: ChainId
): Promise<T> => {
  try {
    const multi = getMulticallContract(signer, chainId)
    const itf = new ethers.utils.Interface(abi)

    const calldata = calls.map(call => [call.address.toLowerCase(), itf.encodeFunctionData(call.name, call.params)])
    const { returnData } = await multi.aggregate(calldata, { blockTag: 'pending' })
    const res = returnData.map((call: any, i: any) => {
      if (call == '0x') {
        call =
          '0x000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000'
      }
      return itf.decodeFunctionResult(calls[i].name, call)
    })
    return res
  } catch (error) {
    throw new Error(error as any)
  }
}
