import { useQuery } from 'react-query'
import { FACTORY_ADDRESS } from 'tombswap-sdk'

import { getContract } from '../../utils'
import factoryAbi from '../../constants/abis/factory.json'
import { useActiveWeb3React } from '..'
import { BigNumber } from 'ethers'

export const useSwapFeeQuery = () => {
  const { library } = useActiveWeb3React()
  const factoryContract = getContract(FACTORY_ADDRESS, factoryAbi, library)

  return useQuery('swap-fee', async () => {
    const fee: BigNumber = await factoryContract.swapFee()

    return fee.toNumber()
  })
}
